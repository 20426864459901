import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Col, Container, Form, FormGroup, Row, Spinner, Table } from 'react-bootstrap'
import 'animate.css';
import axios from 'axios';
import { PATH_PARCEL_VEHICLE } from '../../config';
export default function VehicleType() {
    const [showForm, setShowForm] = useState(false);
    const [hide, setHide] = useState('hide');
    const [vehicles, setVehicles] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [name, setName] = useState('');
    const [limit, setLimit] = useState('');
    const [icon, setIcon] = useState();

    useEffect(() => {
        if (!showForm)
            setTimeout(() => {
                if (!showForm)
                    setHide('hide');
                else
                    setHide('');
            }, 1000);
        else {
            setHide('');
        }
    }, [
        showForm
    ]);

    function LoadData() {
        setIsLoading(true);
        let token = sessionStorage.getItem('token');
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        }
        axios.get(PATH_PARCEL_VEHICLE, config).then((res) => {
            if (res.status === 200) {
                var datas = res.data.vehicles;
                //    debugger;
                setVehicles(datas);
            }
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);
        });
    }

    function CreateVehicle() {
        let token = sessionStorage.getItem('token');
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        }
        const form = new FormData();
        form.append('name', name);
        form.append('limit', limit);
        form.append('icon', icon);

        axios.post(PATH_PARCEL_VEHICLE, form, config).then((res) => {
            setIsLoading(false);
            if (res.status === 200) {
                setShowForm()
                LoadData();
            }
        }).catch((err) => {
            setIsLoading(false);
        })
    }

    function onSubmit(e) {
        setIsLoading(true);
        e.preventDefault();

        CreateVehicle();
    }

    function onDelete(id){
        let token = sessionStorage.getItem('token');
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        }
        setIsLoading(true);

        axios.delete(PATH_PARCEL_VEHICLE + '/' + id,config).then((res) => {
            if(res.status === 200){
                setIsLoading(false);
                LoadData();
            }
        }).catch((err) => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        LoadData();
    }, [])

    return (
        <Container className='mt-3'>
            <Row>
                <Col>
                    <Button onClick={() => {
                        setShowForm(!showForm);
                    }}>Create</Button>
                </Col>
                <Col className={showForm ? 'animate__animated animate__fadeIn ' + hide : 'animate__animated animate__fadeOut ' + hide} sm={12}>
                    <Form onSubmit={onSubmit}>
                        <FormGroup className='mt-3 mb-3'>
                            <Form.Label>
                                Name
                            </Form.Label>
                            <Form.Control type='text' value={name} onChange={(e) => {
                                setName(e.target.value);
                            }} required></Form.Control>
                        </FormGroup>

                        <FormGroup className='mt-3 mb-3'>
                            <Form.Label>
                                Limit (KG)
                            </Form.Label>
                            <Form.Control type='number' value={limit} onChange={(e) => {
                                setLimit(e.target.value);
                            }} required ></Form.Control>
                        </FormGroup>

                        <FormGroup className='mt-3 mb-3'>
                            <Form.Label>
                                Icon
                            </Form.Label>
                            <Form.Control type='file' onChange={(e) => {
                                setIcon(e.target.files[0]);
                            }} required ></Form.Control>
                        </FormGroup>
                        {isLoading ? <Spinner /> : <FormGroup className='mt-3 mb-3'>
                            <Button type='submit' className='mr-1'>Add</Button>
                            <Button type='button' onClick={() => {
                                setShowForm(!showForm);
                            }} variant='secondary'>Cancel</Button>
                        </FormGroup>}

                    </Form>
                </Col>

            </Row>
            <Row className='mt-3'>
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Limit (KG)</th>
                            <th>Icon</th>
                            <th>Controller</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading? <tr><td colSpan={4}> <Spinner /> </td></tr>:  (vehicles && vehicles != undefined ? (
                            vehicles.map((veh) => {
                                return <tr key={veh.id}>
                                    <td>{veh.name}</td>
                                    <td>{veh.limit.toString()}</td>
                                    <td>{veh.icon}</td>
                                    <td>
                                        <ButtonGroup>
                                            <Button variant='secondary'>Edit</Button>
                                            <Button variant='danger' onClick={() => {
                                                onDelete(veh.id);
                                            }}>Delete</Button>
                                        </ButtonGroup>
                                    </td>
                                </tr>;

                            })
                        ) : (
                            <tr>
                                <td colSpan="4">No Data</td>
                            </tr>
                        )) }

                    </tbody>
                </Table>
            </Row>

        </Container>
    )
}
