import React from 'react'

export default function CarIcon() {
  return (
    <> 
  <div className='car-container'>
<div class="base">
  <div class="whole">
    <div class="bug">
      <div class="bug__whole__shadow"></div>
      <div class="bug__tire__shadow__ground"></div>
      <div class="bug__tire__shadow__ground"></div>
      <div class="bug__tire__shadow"></div>
      <div class="bug__tire__shadow"></div>
      <div class="bug__ex"></div>
      <div class="bug__handle__back"></div>
      <div class="bug__body__w"></div>
      <div class="bug__body__f">
        <div class="bug__body__hood"></div>
        <div class="bug__body__hood2"></div>
      </div>
      <div class="bug__body__b__back">
        <div class="engineroom">
          <div class="engine__lines">
            <div class="engine__line"></div>
            <div class="engine__line"></div>
            <div class="engine__line"></div>
            <div class="engine__line"></div>
            <div class="engine__line"></div>
            <div class="engine__line"></div>
          </div>
        </div>
        <div class="window__back__w">
          <div class="window__back__w__inner"></div>
        </div>
      </div>
      <div class="bug__body__b__top">
        <div class="window__f">
          <div class="window__f__inner"></div>
          <div class="window__f__h__inner"></div>
          <div class="window__f__stick"></div>
          <div class="window__f__stick"></div>
          <div class="window__f__headrest"></div>
          <div class="window__f__headrest__stick"></div>
          <div class="window__f__headrest__btm"></div>
          <div class="window__f__handle"></div>
        </div>
        <div class="window__f__h"></div>
        <div class="window__b">
          <div class="window__b__inner"></div>
          <div class="window__b__seat"></div>
        </div>
      </div>
      <div class="bug__longline"></div>
      <div class="bug__door__line__back"></div>
      <div class="bug__door">
        <div class="bug__door__line__right"></div>
        <div class="bug__door__line__left__btm"></div>
        <div class="bug__door__line__left__top"></div>
        <div class="bug__door__box"></div>
        <div class="bug__door__box"></div>
      </div>
      <div class="bug__step__shadow"></div>
      <div class="bug__headlight"></div>
      <div class="bug__fender__f">
        <div class="bug__fender__shadow"></div>
      </div>
      <div class="bug__fender__b">
        <div class="bug__fender__shadow"></div>
        <div class="bug__fender__shadow__box">
          <div class="bug__fender__shadow__cir"></div>
        </div>
      </div>
      <div class="bug__taillight"></div>
      <div class="bug__fender__bb">
        <div class="bug__fender__shadow"></div>
        <div class="bug__fender__shadow__box">
          <div class="bug__fender__shadow__cir"></div>
        </div>
      </div>
      <div class="bug__fender__fb">
        <div class="bug__fender__shadow"></div>
        <div class="bug__fender__shadow__box">
          <div class="bug__fender__shadow__cir"></div>
        </div>
      </div>
      <div class="bug__handle">
        <div class="bug__handle__shadow"></div>
        <div class="bug__handle__handle"></div>
      </div>
      <div class="bug__tire">
        <div class="bug__tire__white">
          <div class="bug__tire__blue">
            <div class="bug__tire__blue__dots"></div>
            <div class="bug__tire__wheel">
              <div class="bug__tire__wheel__logo"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="bug__tire">
        <div class="bug__tire__white">
          <div class="bug__tire__blue">
            <div class="bug__tire__blue__dots"></div>
            <div class="bug__tire__wheel">
              <div class="bug__tire__wheel__logo"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="bug__step"> </div>
      <div class="bug__bumper__back"></div>
      <div class="bug__bumper__front"></div>
    </div>
  </div>
  </div>
</div></>
  )
}
