import React, { useEffect, useState } from 'react'
import { Image, Spinner } from 'react-bootstrap'
import logo from '../assets/images/logo.png';
import { PATH_LOGIN } from '../config';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { checkToken, selectLoggedIn, setStatus, setToken } from "../reducers/slicer";
import '../Login.scss';

export default function Login() {
    const [username, setUsername] = useState("admin@demo.com");
    const [password, setPassword] = useState("12345678");
    const [errorUsername, setErrorUsername] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false)
    const [error, setError] = useState("");
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const loggedIn = useSelector(selectLoggedIn);

    function submitForm(e) {
        e.preventDefault();
        setIsLoading(true);
        axios.post(PATH_LOGIN, {
            email: username,
            password: password
        }).then((res) => {
            console.log(res);

            if(res.status === 200){
                sessionStorage.setItem('token', res.data.token);
                    dispatch(setStatus(true));
                    dispatch(setToken(res.data.token));
                setIsLoading(false);
                // alert('Logged In');
            }else{
                setError(`Failed to login with provided credentials.`);
                setIsLoading(false);
                dispatch(setStatus(false));
            }
        }).catch((err) => {
            setError(`Failed to login with provided credentials.`);
            setIsLoading(false);
            dispatch(setStatus(false));
        });

    }

    useEffect(() => {
        dispatch(checkToken);
    }, []);

    return (
        <section className="ftco-section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 text-center mb-5">
                        <h2 className="heading-section">Vigo Admin</h2>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-7 col-lg-5">
                        <div className="login-wrap p-4 p-md-5">
                            <div className="icon d-flex align-items-center justify-content-center">
                                {/* <span className="fa fa-user-o"></span> */}
                                <Image fluid src={logo} />
                            </div>
                            <h3 className="text-center mb-4">Sign In</h3>
                            <form action="#" className="login-form" onSubmit={submitForm}>
                                <div className="form-group">
                                    <input type="text" className="form-control rounded-left" placeholder="Username"
                                        value={username}
                                        onChange={(e) => {
                                            setUsername(e.target.value);
                                        }}
                                        required />
                                    {errorUsername ? <label className='text-danger'>Please fill username</label> : <></>}
                                </div>
                                <div className="form-group d-flex">
                                    <input type="password" className="form-control rounded-left" placeholder="Password" required
                                        value={password}
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                        }}
                                    />
                                    {errorPassword ? <label className='text-danger'>Please fill password</label> : <></>}
                                </div>
                                <div className="form-group">
                                    {error !== "" ? <label className='text-danger'> {error} </label> : <></>}
                                </div>
                                <div className="form-group">
                                    { isLoading? <Spinner />: <button type="submit" className="form-control btn btn-primary rounded submit px-3">Login</button> }
                                   
                                </div>
                                <div className="form-group d-md-flex">
                                    <div className="w-50">
                                        <label className="checkbox-wrap checkbox-primary">Remember Me
                                            <input type="checkbox" checked onChange={(e) => {}}/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="w-50 text-md-right">
                                        <a href="#">Forgot Password</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
