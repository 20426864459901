import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Card, Col, Container, Form, FormGroup, Image, Row, Spinner, Table } from 'react-bootstrap'
import { PATH_PARCEL_DELIVERY_PRICE, PATH_PARCEL_VEHICLE } from '../../config';
import axios from 'axios';
import CarIcon from './components/CarIcon';
import MotorIcon from './components/MotorIcon';
import FormPanel from './components/FormPanel';

export default function ParcelPrice() {

    const [vehicles, setVehicles] = useState();
    const [prices, setPrices] = useState();
    const [vehicle, setVehicle] = useState();
    const [showOption, setShowOption] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [hide, setHide] = useState('hide');

    // Single Form
    const [start, setStart] = useState(0);
    const [end, setEnd] = useState(0);
    const [price, setPrice] = useState(0.00);
    const [distance, setDistance] = useState(0.00);
    const [priority, setPriority] = useState(0);
    const [valid, setValid] = useState(true);
    const [season, setSeason] = useState(false);
    const [startSeason, setStartSeason] = useState();
    const [endSeason, setEndSeason] = useState();
    const [startPrice, setStartPrice] = useState();

    function LoadData() {
        setIsLoading(true);
        let token = sessionStorage.getItem('token');
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        }

        axios.get(PATH_PARCEL_DELIVERY_PRICE, config).then((res) => {
            if (res.status === 200) {
                var datas = res.data.prices;
                //    debugger;
                setPrices(datas.filter(x=> x.vehicle_id === vehicle.id ));
            }
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);
        });
    }

    function LoadVehicleData() {
        setIsLoading(true);
        let token = sessionStorage.getItem('token');
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        }
        axios.get(PATH_PARCEL_VEHICLE, config).then((res) => {
            if (res.status === 200) {
                var datas = res.data.vehicles;
                //    debugger;
                setVehicles(datas);
            }
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);
        });
    }

    function onDelete(id) {

    }

    function selectVehicleType(veh) {
        setVehicle(veh);
        setShowOption(false);
    }


    useEffect(() => {
        LoadData();
        LoadVehicleData();
    }, []);

    useEffect(() => {
        if (!showForm)
            setTimeout(() => {
                if (!showForm)
                    setHide('hide');
                else
                    setHide('');
            }, 1000);
        else {
            setHide('');
        }
    }, [
        showForm
    ]);

    function CreatePrice() {
        let token = sessionStorage.getItem('token');
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        }
        const form = new FormData();
         form.append('start', start);
         form.append('end', end);
         form.append('price', price)
         form.append('priority', priority);
         form.append('valid', valid);
         form.append('season', season);
         form.append('start_season', startSeason);
         form.append('end_season', endSeason);
         form.append('type', 'normal');
         form.append('vehicle_id', vehicle.id);

        axios.post(PATH_PARCEL_DELIVERY_PRICE, form, config).then((res) => {
            setIsLoading(false);
            if (res.status === 200) {
               LoadData();
            }
        }).catch((err) => {
            setIsLoading(false);
        })
    }


    function GeneratePrice() {
        let token = sessionStorage.getItem('token');
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        }
        const form = new FormData();
        form.append('start', start);
        form.append('end', end);
        form.append('price', price);
        form.append('start_price', startPrice);
        form.append('priority', priority);
        form.append('distance', distance);
        form.append('valid', valid);
        form.append('season', season);
        form.append('start_season', startSeason);
        form.append('end_season', endSeason);
        form.append('type', 'generate');
        form.append('vehicle_id', vehicle.id);

        axios.post(PATH_PARCEL_DELIVERY_PRICE, form, config).then((res) => {
            setIsLoading(false);
            if (res.status === 200) {
                setShowForm()
                LoadData();
            }
        }).catch((err) => {
            setIsLoading(false);
        })
    }

    function onSubmit(e) {
        setIsLoading(true);
        e.preventDefault();

        CreatePrice();
    }

    function onSubmitGenerate(e) {
        setIsLoading(true);
        e.preventDefault();

        GeneratePrice();
    }


    return (<Container>
        {showOption ? <>    <h1>Select Vehicle Type</h1>
            <div className="mt-3 d-flex justify-content-evenly">

                {vehicles != undefined ? vehicles.map((veh) => {
                    return (<div>
                        <a onClick={() => {
                            selectVehicleType(veh);
                        }}>
                            <Card className='motorbike-card '>
                                <Card.Header>
                                    {veh.name === 'Motorbike' ?
                                        <MotorIcon />
                                        : (veh.name === 'Car' ? <CarIcon /> : <Image src={veh.icon} alt='Icon' />)}
                                </Card.Header>
                                <Card.Body>
                                    <h2>{veh.name}</h2>
                                </Card.Body>
                            </Card>
                        </a>
                    </div>);
                }) : <></>}
            </div>
        </> : <>
            <h1>Create Price List</h1>
            <FormPanel createTitle={'Create'} >
                <Form onSubmit={onSubmit}>
                    <FormGroup className='mt-3 mb-3'>
                        <Row>
                            <Col>
                                <Form.Label>
                                    Start (KM)
                                </Form.Label>
                                <Form.Control type='text' value={start} onChange={(e) => {
                                    setStart(e.target.value);
                                }} required></Form.Control>
                            </Col>
                            <Col>
                                <Form.Label>
                                    End (KM)
                                </Form.Label>
                                <Form.Control type='text' value={end} onChange={(e) => {
                                    setEnd(e.target.value);
                                }} required></Form.Control>
                            </Col>
                        </Row>

                    </FormGroup>

                    <FormGroup className='mt-3 mb-3'>
                        <Form.Label>
                            Price (RM)
                        </Form.Label>
                        <Form.Control type='number' value={price} onChange={(e) => {
                            setPrice(e.target.value);
                        }} required ></Form.Control>
                    </FormGroup>

                    <FormGroup className='mt-3 mb-3'>
                        <Form.Label>
                            Priority
                        </Form.Label>
                        <Form.Control type='number' value={priority} onChange={(e) => {
                            setPriority(e.target.value);
                        }} required ></Form.Control>
                    </FormGroup>
                    <FormGroup className='mt-3 mb-3'>

                        <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            label="Is it seasonal"
                            checked={season}
                            onChange={e => {
                                setSeason(e.target.checked);
                            }}
                        />
                    </FormGroup>


                    <FormGroup className='mt-3 mb-3'>

                        <Row>
                            <Col>
                                <Form.Label>Start Season</Form.Label>
                                <Form.Control type='date'></Form.Control>
                            </Col>
                            <Col>
                                <Form.Label>End Season</Form.Label>
                                <Form.Control type='date'></Form.Control>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup className='mt-3 mb-3'>

                        <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            label="Valid"
                            checked={valid}
                            onChange={e => {
                                setValid(e.target.checked);
                            }}
                        />
                    </FormGroup>
                    {isLoading ? <Spinner /> : <FormGroup className='mt-3 mb-3'>
                        <Button type='submit' className='mr-1'>Add</Button>
                        <Button type='button' onClick={() => {
                            setShowForm(!showForm);
                        }} variant='secondary'>Cancel</Button>
                    </FormGroup>}

                </Form>
            </FormPanel>

            <FormPanel createTitle={'Generate'}>
                <Form onSubmit={onSubmitGenerate}>
                    <FormGroup className='mt-3 mb-3'>
                        <Row>
                            <Col>
                                <Form.Label>
                                    Start (KM)
                                </Form.Label>
                                <Form.Control type='text' value={start} onChange={(e) => {
                                    setStart(e.target.value);
                                }} required></Form.Control>
                            </Col>
                            <Col>
                                <Form.Label>
                                    End (KM)
                                </Form.Label>
                                <Form.Control type='text' value={end} onChange={(e) => {
                                    setEnd(e.target.value);
                                }} required></Form.Control>
                            </Col>
                        </Row>

                    </FormGroup>
                    <FormGroup className='mt-3 mb-3'>
                        <Form.Label>
                            Distance (KM)
                        </Form.Label>
                        <Form.Control type='number' value={distance} onChange={(e) => {
                            setDistance(e.target.value);
                        }} required ></Form.Control>
                    </FormGroup>

                    <FormGroup className='mt-3 mb-3'>

                        <Row>
                            <Col>
                                <Form.Label>
                                    Start Price (RM)
                                </Form.Label>
                                <Form.Control type='number' value={startPrice} onChange={(e) => {
                                    setStartPrice(e.target.value);
                                }} required ></Form.Control>
                            </Col>
                            <Col>
                                <Form.Label>
                                    Price (RM)
                                </Form.Label>
                                <Form.Control type='number' value={price} onChange={(e) => {
                                    setPrice(e.target.value);
                                }} required ></Form.Control>
                            </Col>
                        </Row>


                    </FormGroup>

                    <FormGroup className='mt-3 mb-3'>
                        <Form.Label>
                            Priority
                        </Form.Label>
                        <Form.Control type='number' value={priority} onChange={(e) => {
                            setPriority(e.target.value);
                        }} required ></Form.Control>
                    </FormGroup>
                    <FormGroup className='mt-3 mb-3'>

                        <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            label="Is it seasonal"
                            checked={season}
                            onChange={e => {
                                setSeason(e.target.checked);
                            }}
                        />
                    </FormGroup>


                    <FormGroup className='mt-3 mb-3'>

                        <Row>
                            <Col>
                                <Form.Label>Start Season</Form.Label>
                                <Form.Control type='date'></Form.Control>
                            </Col>
                            <Col>
                                <Form.Label>End Season</Form.Label>
                                <Form.Control type='date'></Form.Control>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup className='mt-3 mb-3'>

                        <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            label="Valid"
                            checked={valid}
                            onChange={e => {
                                setValid(e.target.checked);
                            }}
                        />
                    </FormGroup>
                    {isLoading ? <Spinner /> : <FormGroup className='mt-3 mb-3'>
                        <Button type='submit' className='mr-1'>Generate</Button>
                        <Button type='button' onClick={() => {
                            setShowForm(!showForm);
                        }} variant='secondary'>Cancel</Button>
                    </FormGroup>}

                </Form>
            </FormPanel>


            <Row className='mt-3'>
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th>Start (KM)</th>
                            <th>End (KM)</th>
                            <th>Price (RM)</th>
                            <th>Vehicle</th>
                            <th>Controller</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? <tr><td colSpan={4}> <Spinner /> </td></tr> : (prices && prices != undefined ? (
                            prices.map((pr) => {
                                return <tr key={pr.id}>
                                    <td>{pr.start}</td>
                                    <td>{pr.end.toString()}</td>
                                    <td>{pr.price}</td>
                                    <td>{ vehicles.filter( x=> x.id === pr.vehicle_id)[0].name }</td>
                                    <td>
                                        <ButtonGroup>
                                            <Button variant='secondary'>Edit</Button>
                                            <Button variant='danger' onClick={() => {
                                                onDelete(pr.id);
                                            }}>Delete</Button>
                                        </ButtonGroup>
                                    </td>
                                </tr>;

                            })
                        ) : (
                            <tr>
                                <td colSpan="4">No Data</td>
                            </tr>
                        ))}

                    </tbody>
                </Table>
            </Row>
        </>}

    </Container>

    )
}
