import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { PATH_USER } from '../config';


const initialState = {
    email: "",
    loggedIn: false,
    token: ""
};

export const slicer = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        checkToken(state){
            let token = sessionStorage.getItem('token')??"";

            if(token === "" || token === undefined){
                state.loggedIn=false;
            }
            else{
                state.loggedIn=true;
            }
        },
        setStatus(state, action){
            state.loggedIn = action.payload;
        },
        setToken(state, action){
            state.token = action.payload;
        }
    }
});

export const {checkToken, setStatus, setToken}  = slicer.actions;
export const selectEmail = (state) => state.admin.email;
export const selectLoggedIn = (state) => state.admin.loggedIn;
export const selectToken = (state) => state.admin.token;
export default slicer.reducer;