import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'

export default function FormPanel({ children, createTitle }) {
    const [showForm, setShowForm] = useState(false);
    const [hide, setHide] = useState('');

    useEffect(() => {
        if (!showForm)
            setTimeout(() => {
                if (!showForm)
                    setHide('hide');
                else
                    setHide('');
            }, 1000);
        else {
            setHide('');
        }
    }, [
        showForm
    ]);
    return (
        <Row className={'mb-3'}>
            <Col>
                <Button onClick={() => {
                    setShowForm(!showForm);
                }}>{createTitle}</Button>
            </Col>
            <Col className={showForm ? 'animate__animated animate__fadeIn ' + hide : 'animate__animated animate__fadeOut ' + hide} sm={12}>
                {children}
            </Col>
        </Row>
    )
}
