import React from 'react'

export default function MotorIcon() {
  return (
    <div class="container-bike">
    <div class="bike-body">
      <div class="seat"></div>
      <div class="cover"></div>
      <div class="lamp"></div>
      <div class="motor">
        <div class="part-1">
          <div class="part-1-top"></div>
          <div class="part-1-bottom"></div>
        </div>
        <div class="part-2">
          <div class="part-2-base">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
      </div>
      <div class="front"></div>
      <div class="back"></div>
    </div>
    <div class="tire"></div>
    <div class="tire"></div>
    <div class="smoke"></div>
  </div>
  )
}
