import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from '../pages/Login'
import Dashboard from '../pages/Dashboard';
import 'weather-icons/css/weather-icons.css';
import '../Vigo.scss';
import Header from './Header';
import SideBar from './SideBar';
import Breadcrumb from './Breadcrumb';
import VehicleType from '../pages/Parcel/VehicleType';
import ParcelPrice from '../pages/Parcel/ParcelPrice';

export default function AppRouters(children) {
  return (
  <>
      <Header />
      <div id="layoutSidenav">
      <SideBar />
      <div id="layoutSidenav_content">
                <main>
        {/* <Breadcrumb title={'Dashboard'} /> */}
   <BrowserRouter>
   <Routes>
    <Route path='/' element={<Dashboard  />} />
    <Route path='/vehicletype' element={ <VehicleType /> } />
    <Route path='/parcelprice' element={<ParcelPrice />} />
   </Routes>
   </BrowserRouter>
   </main>
   </div>
   </div>
</>
  )
}
