import logo from './logo.svg';
import './App.css';
import AppRouters from './components/AppRouters';
import Login from './pages/Login';
import { selectLoggedIn, checkToken, setStatus, setToken } from './reducers/slicer';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { PATH_USER } from './config';
import { Spinner } from 'react-bootstrap';

function App() {
  const loggedIn = useSelector(selectLoggedIn);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

function userCheckToken(){
  setIsLoading(true);
  let token = sessionStorage.getItem('token');

  axios.get(PATH_USER,{ headers: { Authorization: `Bearer ${token}` }}).then((res) => {
    if(res.status === 200){
      dispatch(setToken)
      dispatch(setStatus(true));
    }
    else{
      dispatch(setStatus(false));
    }
    setIsLoading(false);
}).catch((err) =>{
  dispatch(setStatus(false));
  setIsLoading(false);
});
}

  useEffect(() => {
    userCheckToken();
    //dispatch(checkToken());
}, []);

  return (
    <>
    { loggedIn ?  <AppRouters />:  (isLoading? <Spinner /> : <Login />) } 
   
    {/* <AppRouters /> */}
    </>
  );
}

export default App;
